//import tms styles

@import '../../styles/invoices.scss';

donation-line-item {
.donation-option{
  transition: $fast;
}
}

