//import tms styles
@import "../../../../styles/invoices.scss";

tournament-leaderboard-result-line-item {
  @include v-stack(unset, unset);
  width: 100%;
  @include padding($x-large-pad);

  .flex-end {
    width: 100%;
    @include h-stack(flex-end, flex-end);
    > gs-item-list {
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  .position-width {
    width: 30px;
  }

  .team-width {
    width: 170px;
    @include large-phone() {
      width: 100%;
      justify-content: center;
    }
  }

  .centered {
    width: 100%;
    justify-content: center;
  }

  .groups {
    width: 100%;
  }
  .teammate {
    @include h-stack(center, center);
    gap: $medium-pad;
    .teammate-image {
      border: 2px solid $grey-100;
      @include padding($x-small-pad);
      border-radius: $circle-border-radius;
    }
  }

  .green {
    .teammate-image {
      border-color: $green;
    }
  }

  .orange {
    
    .teammate-image {
      border-color: $team-2-color;
    }
  }

  .score {
    height: 30px;
    @include v-stack(center, center);
    justify-content: center !important;
    align-items: center !important;
  }
  .birdie {
    border: 2px solid $team-1-color;
    border-radius: $circle-border-radius;
  }

  .bogey {
    border: 2px solid $grey-500;
    border-radius: $small-border-radius;
    &.double {
      background-color: $grey-200;
    }
  }

  .eagle {
    border: 2px solid $green;
    border-radius: $circle-border-radius;
    background: rgba($green, 0.4);
  }

  .align-end {
    align-items: flex-end !important;
  }

  .winner{
    border: $light-border;
    @include padding($large-pad);
    border-radius: $medium-large-border-radius;
    &.green{
      border-color: $team-1-color;
      border-width: 2px;
      background: rgba($team-1-color, .2);
      width: 100%;
    }
    &.orange{
      border-color: $team-2-color;
      border-width: 2px;
      background: rgba($team-2-color, .2);
      width: 100%;
    }
  }

  .team{
    width: 100%;
    border: $light-border;
    @include padding($large-pad);
    border-radius: $medium-large-border-radius;
  }
}
