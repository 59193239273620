$header-height: 60px;

$no-pad: 0;
$xx-small-pad: 1px;
$x-small-pad: 2px;
$small-pad: 4px;
$medium-pad: 8px;
$medium-large-pad: 12px;
$large-pad: 16px;
$x-large-pad: 24px;
$xx-large-pad: 36px;
$small-layout-pad: 56px;
$medium-layout-pad: 84px;
$large-layout-pad: 128px;

//legacy
$huge-pad: 48px;
$large-huge-pad: 24px;
$small-medium-pad: 8px;

//border-radius
$small-radius: 2px;
$medium-radius: 5px;
$medium-large-radius: 8px;
$large-radius: 10px;
$huge-radius: 100px;

//screen sizes
$tiny-screen: 350px;
$small-screen: 550px;
$medium-screen: 800px;