//import tms styles
@import '../../../styles/invoices.scss';

event-registrants {
  > gs-list-page {
    @include fill();
  }

  gs-page-layout{
    .header{
      z-index: 1;
    }
  }
}