@import "../styles/invoices";

registration-order-summary {
  width: 100%;

  gs-item-list {
    width: 100%;

    .vertical {
      gap: $xx-large-pad;
    }
  }

  .title {
    font-size: $medium-font;
    font-weight: $bold-font-weight;
    margin-bottom: $medium-large-pad;
  }
}
