@import "../styles/invoices";

registration-order-summary-line-item {
  border-bottom: $light-border;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  width: 100%;


}
