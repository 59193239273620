//borders
$small-border-radius: 2px;
$medium-border-radius: 4px;
$medium-large-border-radius: 8px;
$large-border-radius: 16px;
$x-large-border-radius: 24px;
$circle-border-radius: 100%;

$light-border: 1px solid rgba(0,0,0,.1);
$medium-border:1px solid rgba(0,0,0,.3);
$no-border: 1px solid rgba(0,0,0,0);
$dark-border: 1px solid rgba(100,100,100,.3);

//box-shadows
$shadow-color: rgba(0, 0, 0, .20);
$base-layer-shadow: 0 2px 2px rgba(0, 0, 0, .25);
$first-layer-shadow: 0 4px 12px rgba(0, 0, 0, .25);
$second-layer-shadow: 0 8px 20px rgba(0, 0, 0, .17);
$third-layer-shadow: 0 8px 24px rgba(0, 0, 0, .10);
$no-top-second-layer-shadow: 0 6px 8px rgba(0, 0, 0, .20);

//animations
$fast: .3s;
$medium: .5s;
$slow: 1s;

//scale
$scale-short: scale(1.05);
$scale-medium: scale(1.1);

//form element heights
$form-element-height: 40px;
