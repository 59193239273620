@mixin tablet(){
  @media screen and (max-width: 1100px){
      @content
  }
};

@mixin large-phone(){
  @media screen and (max-width: 900px){
      @content
  }
};

@mixin small-phone(){
  @media screen and (max-width: 400px){
      @content
  }
}