//import tms styles
@import '../../styles/invoices.scss';

events-list {
  @include v-stack(unset, unset);
  @include fill();
  
  > gs-list-page {
    @include fill();
  }

  gs-item-list{
    width: 100%;
  }
  
}
