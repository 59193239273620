//import tms styles
@import '../../../../styles/invoices.scss';

event-leaderboards {
  > gs-list-page {
    @include fill();
  }

  gs-page-layout{
    .header{
      z-index: 1;
    }
  }
}