@import "../styles/invoices";

payment-option {
  width: 100%;

  .groups {
    gs-item-list {
      .vertical.medium-large-gap {
        gap: 0;
      }
    }
  }
}
