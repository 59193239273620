@mixin fill() {
  width: 100%;
  height: 100%;
}

@mixin sticky($top) {
  position: sticky;
  top: $top;
  z-index: 1;
}

@mixin stack($justify, $align, $direction) {
  display: inline-flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin v-stack($justify, $align) {
  @include stack($justify, $align, column);
}

@mixin h-stack($justify, $align) {
  @include stack($justify, $align, row);
}

@mixin flex-width($width, $max, $min) {
  width: $width;
  max-width: $max;
  min-width: $min;
}

@mixin flex-height($height, $max, $min) {
  height: $height;
  max-height: $max;
  min-height: $min;
}

@mixin padding($size) {
  padding: $size;
  box-sizing: border-box;
}

@mixin h-padding($size) {
  padding-left: $size;
  padding-right: $size;
  box-sizing: border-box;
}

@mixin v-padding($size) {
  padding-top: $size;
  padding-bottom: $size;
  box-sizing: border-box;
}

@mixin rounded-border($border-radius, $border-color) {
  border: $border-color;
  border-radius: $border-radius;
}

@mixin square($size) {
  @include flex-height($size, $size, $size);
  @include flex-width($size, $size, $size);
}

@mixin circle($size) {
  @include square($size);
  border-radius: 100%;
}

@mixin selectable-item() {
  cursor: pointer;
  transition: $fast;

  &:hover {
    background: rgba(10, 10, 10, 0.01);
  }
}

@mixin user-action-button {
  cursor: pointer;
}

@mixin user-card {
  min-width: 300px;
  min-height: 120px;
  border-radius: $medium-large-border-radius;
  box-shadow: $first-layer-shadow;
  overflow: hidden;
  background: $white;
  color: $grey-800;
}

@mixin selectable {
  cursor: pointer;
  transition: $fast;

  &:hover {
    background: rgba(10, 10, 10, 0.01);
  }
}

@mixin list-page-content {
  &.open {
    filter: blur(8px);
  }

  gs-action-bar {
    @include padding($large-pad);
    box-shadow: $second-layer-shadow;

    .search {
      width: 100%;
      @include v-stack(flex-start, flex-start);
      flex-wrap: wrap;

      gs-input {
        margin-right: $small-pad;
        width: 100%;
        max-width: 400px;

        input {
          width: 100%;
        }
      }

      gs-toggle {
        margin: $medium-pad;
      }

      gs-item-list {
        margin-top: $large-pad;
      }
    }
  }
}

@mixin white-side-panel-navigation {
  gs-side-panel-navigation {
    @include gs-font($x-small-font, $bold-font-weight, $large-letter-spacing);
  }
}

@mixin sticky-side-navigation {
  gs-side-panel-navigation {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    box-shadow: $third-layer-shadow;
  }
  gs-action-drawer {
    position: sticky;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(5px);

    gs-button {
      white-space: nowrap;
    }
    @include large-phone() {
      gs-item-list {
        gs-list-item {
          max-width: 100%;
        }
      }
    }
  }
}

@mixin filter-buttons {
  @include h-stack(unset, unset);
  overflow: auto;
  margin: $medium-pad;

  gs-item-list {
    margin-top: $medium-pad;

    gs-button {
      margin: $small-pad;
      white-space: nowrap;
    }
  }
}

@mixin admin-page {
  @include v-stack(unset, unset);
  @include fill();
  position: relative;
  gs-list-page {
    height: 100%;
  }
}

@mixin side-panel-page {
  @include v-stack(unset, unset);
  width: 100%;
  height: 100%;
  gs-side-panel-page {
    height: 100%;
    overflow: hidden;
    gs-form {
      height: 100%;
      overflow: auto;
    }
  }
}

@mixin remove-page {
  .delete-header {
    @include h-stack(center, center);
    gap: $large-pad;
    > .icon {
      color: $red;
    }
  }
  .remove-container {
    @include v-stack(inherit, inherit);
    gap: $large-pad;
    .disclaimer {
      @include gs-font($small-font, $regular-font-weight, inherit);
      opacity: 0.7;
    }
    > .item {
      @include v-stack(unset, unset);
      @include padding($x-large-pad);
      border: $light-border;
      border-radius: $medium-large-border-radius;
      border-color: $red;
      @include fill();
    }
  }
}

@mixin success-page {
  .success-header {
    @include h-stack(center, center);
    gap: $large-pad;
    > .icon {
      color: $green;
    }
  }
  .success-container {
    @include v-stack(inherit, inherit);
    gap: $large-pad;
    .disclaimer {
      @include gs-font($small-font, $regular-font-weight, inherit);
      opacity: 0.7;
    }
    > .item {
      @include v-stack(unset, unset);
      @include padding($x-large-pad);
      border: $light-border;
      border-radius: $medium-large-border-radius;
      border-color: $green;
      @include fill();
    }
  }
}


@mixin warn-page {
  .warn-header {
    @include h-stack(center, center);
    gap: $large-pad;
    > .icon {
      color: $orange;
    }
  }
  .warn-container {
    @include v-stack(inherit, inherit);
    gap: $large-pad;
    .disclaimer {
      @include gs-font($small-font, $regular-font-weight, inherit);
      opacity: 0.7;
    }
    > .item {
      @include padding($x-large-pad);
      border: $light-border;
      border-radius: $medium-large-border-radius;
      border-color: $orange;
      @include fill();
    }
  }
}

@mixin form-header {
  gs-form {
    > gs-action-bar {
      @include padding($x-large-pad);
      border-bottom: $light-border;
      font-size: $large-font;
    }
  }
}
