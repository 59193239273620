@import "../../styles/invoices";

customer-invoice-total-banner {
  .total-container {
    border-radius: $medium-large-radius;
    padding: $x-large-pad;

    .total-info {
      justify-content: space-between;

      .total {
        font-size: $large-font;
      }
    }
  }
}
