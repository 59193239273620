@import "../../styles/invoices";

customer-invoice-pay {
  @include side-panel-page();

  gs-form-section:has(> gs-item-list > .no-pad) {
    border-bottom: none;
    padding: 0;
  }

  .no-pad {
    > gs-list-item {
      width: 100%;
    }

    gs-list-item {

      .total-container {
        padding: $xx-large-pad;

        .total-info {
          justify-content: space-between;

          .total {
            font-size: $large-font;
          }
        }
      }
    }
  }

  gs-radio-group.is-ltr {
    padding: 8px 0;
  }

  .inline {
    display: flex;
    gap: $x-large-pad;
  }

  .captcha-wrapper {
    text-align: left;
  }

  @include large-phone {
    .inline {
      flex-direction: column;
    }

    #exp-cvv {
      gap: $xx-large-pad;
    }
  }

}

.dark{
  pay-invoice {
    .no-pad {
      gs-list-item {
        .total-container {
          background-color: #003b3e;
        }
      }
    }
  }
}