@import "../../styles/invoices";

customer-invoice-details {
  >gs-list-page{
    @include fill();
  }

  .section {
    border-bottom: $light-border;
    padding: $small-layout-pad $x-large-pad;
    width: 100%;

    .title {
      font-size: $medium-font;
      font-weight: $bold-font-weight;
      margin-bottom: $medium-large-pad;
    }
  }

  gs-empty-list {
    margin: 120px auto;
  }

  >gs-list-page{
    @include fill();
  }

  @include large-phone {
    .section {
      .details {
        flex-direction: column;

        .right {
          justify-content: start;
        }
      }
    }
  }
}

.dark{
  invoice-details {
    .section{
      .paid{
        background-color: #003B3E;
      }
      .due{
        background-color: #003B3E;
      }
    }
  }
}
