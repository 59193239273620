$font-family: roboto, filson-soft, sans-serif;

//App font sizes
$xxx-small-font: 8px;
$xx-small-font: 10px;
$x-small-font: 12px;
$small-font: 14px;
$medium-font: 16px;
$large-font: 20px;
$x-large-font: 24px;
$xx-large-font: 24px;

//Display fonts
$small-display-font: 28px;
$medium-display-font: 32px;
$large-display-font: 40px;
$x-large-display-font: 48px;

//font weights

$thin-font-weight: 100;
$regular-font-weight: 400;
$medium-font-weight: 500;
$bold-font-weight: 700;

//letter-spacing
$x-small-letter-spacing: .25px;
$small-letter-spacing: 1px;
$medium-letter-spacing: 1.5px;
$large-letter-spacing: 2px;

//line-height
$compact-line-height: 1.2;
$regular-line-height: 1.4;
$spacious-line-height: 1.6;

//mixins
@mixin gs-font($size,$weight,$spacing){
    font-size: $size;
    font-weight: $weight;
    letter-spacing: $spacing;
}
@mixin title-font(){
    font-weight: $thin-font-weight;
    font-size: $large-font;
}
@mixin detail-text-font(){
    font-weight: $thin-font-weight;
    font-size: $medium-font;
}
@mixin subtle-text-font(){
    font-weight: $thin-font-weight;
    font-size: $medium-font;
}
@mixin faint-text(){
    font-size: $medium-font;
    opacity: .5;
}
@mixin tiny-text(){
    font-size: $x-small-font;
}

@mixin display-large(){
    font-size: $x-large-display-font ;
}

@mixin displayMedium(){
    font-size: $large-display-font;
}

@mixin displaySmall(){
    font-size: $medium-display-font;
}

@mixin headlineLarge(){
    font-size: $small-display-font;
}

@mixin headlineMedium(){
    font-size: $x-large-font;
}

@mixin headlineSmall(){
    font-size: $large-font;
}

@mixin titleLarge(){
    font-size: $large-font;
}

@mixin titleMedium(){
    font-size: $medium-font;
}

@mixin titleSmall(){
    font-size: $small-font;
}

@mixin bodyLarge(){
    font-size: $medium-font;
}

@mixin bodyMedium(){
    font-size: $small-font;
}

@mixin bodySmall(){
    font-size: $x-small-font;
}

@mixin labelLarge(){
    font-size: $x-small-font;
}

@mixin labelMedium(){
    font-size: $xx-small-font;
}

@mixin labelSmall(){
    font-size: $xxx-small-font;
}