@import "../styles/invoices";

golfstatus-payments {
  height: 100%;
  width: 100%;
  font-synthesis: none;
  -webkit-font-smoothing: subpixel-antialiased ;
  text-rendering: geometricPrecision;
  

  gs-side-navigation {
    background: black;
    height: 100%;
  }

  gs-list-page {
    width: 100%;
  }

  page-content {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    gs-page-banner {
      flex-shrink: 0;
      width: 100%;

      .horizontal {
        align-items: center;
      }
    }
  }

  .border-bottom {
    border-bottom: $light-border;
  }

  .full-width {
    width: 100%;
  }

}
