@import '../styles/invoices';

stripe-card {
  display: flex;
  flex-direction: column;
  gap: $large-pad;

  .row {
    display: flex;
    gap: $large-pad;
    justify-content: space-between;
  }

  .field-value {
    background-color: $white;
    border: $light-border;
    border-radius: $medium-large-border-radius;
    margin-bottom: 6px;
    padding: 12px;
  }

  @include large-phone {
    .row {
      flex-direction: column;
    }
  }
}
