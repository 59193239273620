@import 'styles/constants.scss';


.light{
  background: white;
}

.no-wrap {
  white-space: nowrap;
}

.ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

gs-side-panel-page {
  gs-page-banner {
    &.hidden {
      .gs-banner-content {
        transform: translateY(-100%);
      }
    }
  }
}


gs-button {
  overflow: hidden;
}

.golfstatus-app{ 
  &.dark {
  input {
    color: white;
    color-scheme: dark;
  }

  ::placeholder {
    color: white;
  }
}}


h1{
  all: unset;
}
h2{
  all: unset;
}
h3{
  all: unset;
}
h4{
  all: unset;
}
h5{
  all: unset;
}


