@import "../../styles/invoices";

customer-invoice-payment-confirmation {
  @include side-panel-page();

  .gs-form-header {
    padding: $x-large-pad $medium-large-pad;

    .header {
      .button-icon {
        color: #86E99A;
        width: 20px;
        height: 20px;
      }
    }
  }

  .payment-text {
    color: $dark-70-percent;
    font-size: $small-font;
    line-height: $large-font;
  }

  .container {
    border: $light-border;
    border-radius: $medium-large-border-radius;
    padding: $x-large-pad;
    display: flex;
    flex-direction: column;
    gap: $xx-large-pad;

    .total-info {
      justify-content: space-between;

      .total {
        font-size: $large-font;
      }
    }

    .total-container {
      background-color: #DDFFE4;
      border-radius: $medium-large-radius;
      padding: $x-large-pad;
    }
  }

  @include large-phone {
    .container {
      flex-direction: column;
    }
  }
}
