@import "../../styles/invoices";

customer-invoice-summary {
  .details {
    justify-content: space-between;

    .right {
      justify-content: end;
      width: 100%;
    }

    .small-gap {
      margin-bottom: $medium-large-pad;
    }
  }

  @include large-phone {
    .details.horizontal {
      flex-direction: column;

      .right {
        justify-content: start;
      }
    }
  }
}
